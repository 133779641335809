<script lang="ts" setup>
import type { BlokGridCard } from '~~/types/storyblok/bloks'
const props = defineProps<{ blok: BlokGridCard | undefined }>()

const icon = computed(() => {
  return props.blok?.icon || ''
})

const iconSize = computed(() => {
  return Number(props.blok?.icon_size) || 70
})

const isLargeCard = computed(() => {
  return props.blok?.card_size === 'lg'
})

const title = computed(() => {
  return props.blok?.title
})

const richText = computed(() => {
  return props.blok?.description ? renderRichText(props.blok?.description) : ''
})

const alignmentClass = computed(() => {
  return props.blok?.alignment === 'center'
    ? 'card-align-center'
    : 'card-align-left'
})
</script>

<template lang="pug">
div(
  v-editable="blok"
  class="blok-grid-card flex flex-col justify-start gap-24 py-18 px-18 md:py-24 xl:p-36 border-b border-blue-dark/10 md:border-0"
  :class="[alignmentClass, isLargeCard ? 'xl:text-md' : 'text-base']"
)
  div(class="icon flex flex-row md:flex-col items-center gap-18 w-full sm:w-auto")
    div(:class="iconSize >= 70 ? 'text-[0.66px] md:text-[1px]' : ''")
      MaterialSymbol(
        v-if="icon"
        :size="iconSize"
        :size-unit="iconSize >= 70 ? 'em' : 'px'"
      ) {{ icon }}

    h3(class="m-0" :class="{ 'heading-2': isLargeCard }") {{ title }}
  div(
    v-if="richText"
    v-html="richText"
    class="content max-w-prose-tight"
  )
</template>

<style lang="postcss" scoped>
.blok-grid-card {
  &.card-align-center {
    @apply text-center items-center;
    .icon,
    .content {
      @apply text-center justify-center;
    }
  }
  &.card-align-left {
    @apply text-left;
  }
}
</style>
